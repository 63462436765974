<template>
  <section class="section-wrap">
    <div class="section-wrap__container">
      <div class="intro">
        <span v-if="title" class="intro__title">{{ title }}</span>
        <h2 v-if="subtitle" class="intro__subtitle" v-html="subtitle"></h2>
        <p v-if="description" class="intro__desc" v-html="description"></p>
      </div>
      <slot></slot>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    title: String,
    subtitle: String,
    description: String
  }
}
</script>

<style lang="sass" scoped>
.section-wrap
  padding: 0 24px
  text-align: center
  position: relative
  margin: 50px 0
  overflow: hidden
  @media screen and ( min-width: 425px )
    margin: 100px 0
  @media screen and ( min-width: 1024px )
    margin: 200px 0
  @media screen and ( min-width: 2560px )
    margin: 400px 0
  &:before
    background-repeat: no-repeat
    background-size:  cover
    background-position: top
    display: block
    position: absolute
    left: 0
    right: 0
    height: 50px
    top: -50px
    @media screen and ( min-width: 425px )
      height: 100px
      top: -100px
    @media screen and ( min-width: 1024px )
      height: 200px
      top: -200px
    @media screen and ( min-width: 2560px )
      height: 300px
      top: -300px
  &:after
    background-repeat: no-repeat
    background-size:  cover
    background-position: bottom
    display: block
    position: absolute
    left: 0
    right: 0
    height: 50px
    bottom: -50px
    @media screen and ( min-width: 425px )
      height: 100px
      bottom: -100px
    @media screen and ( min-width: 1024px )
      height: 200px
      bottom: -200px
    @media screen and ( min-width: 2560px )
      height: 300px
      bottom: -300px
  &__container
    max-width: 1440px
    margin: 0 auto

.intro
  max-width: 609px
  margin: 0 auto
  text-align: inherit
  &__subtitle
    font-weight: 600
    font-size: 24px
    line-height: 135%
    color: #363636
    display: block
    margin: 0 auto 16px auto
    text-align: inherit
    max-width: 474px
    @media screen and ( min-width: 1024px )
      font-size: 32px
      margin-bottom: 16px !important
    &--brand
      color: #FF6441
  &__title
    font-size: 16px
    line-height: 18px
    letter-spacing: 2px
    color: #FF6441
    margin-bottom: 16px
    display: block
    text-align: inherit
  &__desc
    font-size: 16px
    line-height: 150%
    letter-spacing: 0.16px
    color: #7A7A7A
    margin-bottom: 24px
    text-align: inherit
    // &--bold
    //   font-weight: 600
  p
    font-weight: 400
    margin-bottom: 24px
    font-size: 16px
    color: #7A7A7A
    margin-left: 30px
    margin-right: 30px
</style>
